var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.variation)?_c('b-modal',{attrs:{"id":"modifiers","visible":_vm.show,"title":_vm.locale === 'en' ? _vm.variation.name : ( _vm.variation.alt_lang || _vm.variation.name),"centered":"","scrollable":""},on:{"show":_vm.init,"shown":_vm.selectRequiredModifiers,"hidden":_vm.reset},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ cancel }){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('h5',{staticClass:"text-capitalize m-0"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('total'))+": "+_vm._s(_vm.toCurrency(_vm.variation.original_price + _vm.selectedModifiersPrice))+"\n\t\t\t")]),_vm._v(" "),_c('div',[_c('b-button',{staticClass:"text-capitalize",attrs:{"variant":"secondary","size":"sm"},on:{"click":function($event){return cancel()}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('cancel'))+"\n\t\t\t\t")]),_vm._v(" "),_c('b-button',{staticClass:"text-capitalize",attrs:{"variant":"success","size":"sm"},on:{"click":_vm.applyModifiers}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t('add item'))+"\n\t\t\t\t")])],1)])]}}],null,false,3327516785)},_vm._l((_vm.variation.modifiers),function(modifier,modifierIndex){return _c('b-form-group',{key:modifierIndex,attrs:{"label":modifier.name,"label-class":"text-capitalize"}},[_c(modifier.multi_choice ? 'b-form-checkbox-group' : 'b-form-radio-group',{tag:"component",attrs:{"name":`${modifier.id}-${modifier.name.toLowerCase()}`}},_vm._l((modifier.items),function(item,itemIndex){return _c(modifier.multi_choice ? 'b-form-checkbox' : 'b-form-radio',{key:itemIndex,tag:"component",attrs:{"disabled":_vm.validateModifier(_vm.variation, modifier, item),"value":JSON.stringify({
					group_id: modifier.id,
					item_id: item.id,
					item_name: item.name,
					price: item.price,
					qty: 1,
					multi_choice: modifier.multi_choice
				})},nativeOn:{"change":function($event){return _vm.checkModifiers.apply(null, arguments)}}},[_c('p',{staticClass:"mb-0"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.locale === 'en' ? item.name : ( item.alt_lang || item.name))+"\n\t\t\t\t")]),_vm._v(" "),_c('small',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.toCurrency(item.price))+"\n\t\t\t\t")])])}),1)],1)}),1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }