const actions = {
	nuxtServerInit ({ commit }, { req }) {
		commit('setUser', req.session.user || null)
		commit('setLocation', req.session.location || null)
		commit('setSelectedStore', req.session.selectedStore || null)
		commit('setState', {
			key: 'referralCode',
			value: req.session.referral_code || null
		})
		commit('setLocale', req.cookies.lang || 'en')

		if (req.session.cart) {
			commit('setState', {
				key: 'cart',
				value: req.session.cart
			})
		}
	},
	updateSession (ctx, data) {
		return this.$axios.$post('/api/session', data)
	},
	register (ctx, data) {
		return this.$axios.$post('/api/auth/register', data)
	},
	resendOtp () {
		return this.$axios.$post('/api/auth/otp/resend')
	},
	verifyOtp (ctx, data) {
		return this.$axios.$post('/api/auth/otp/verify', data).then((response) => {
			if (response.code === 200) {
				ctx.commit('setUser', response.data.info)
			}

			return response
		})
	},
	verifyAccount (ctx, data) {
		return this.$axios.$post('/api/auth/user/verify', data)
	},
	login (ctx, data) {
		return this.$axios.$post('/api/auth/login', data).then((response) => {
			if (response.code === 200 && ((data.email && response.data.info.is_email_verified) || (data.phone && response.data.info.is_phone_verified))) {
				ctx.commit('setUser', response.data.info)
			}

			return response
		})
	},
	logout () {
		return this.$axios.$get('/api/auth/logout')
	},
	forgotPassword (ctx, data) {
		return this.$axios.$post('/api/auth/password/forgot', data)
	},
	resetPassword (ctx, data) {
		return this.$axios.$post('/api/auth/password/reset', data)
	},
	changePassword (ctx, data) {
		return this.$axios.$post('/api/auth/password/change', data)
	},
	changeMobile (ctx, data) {
		return this.$axios.$post('/api/auth/mobile/change', data)
	},
	changeEmail (ctx, data) {
		return this.$axios.$post('/api/auth/email/change', data)
	},
	getPlaces (ctx, data) {
		return this.$axios.$get('/api/maps/places', {
			params: Object.assign({
				key: ctx.state.merchant.settings.integrations.google_maps.api_token || process.env.GOOGLE_MAPS_API_KEY,
				components: ctx.state.merchant.settings.general.country_code
					? `country:${ctx.state.merchant.settings.general.country_code.toLowerCase()}`
					: null
			}, data)
		})
	},
	getPlace (ctx, data) {
		return this.$axios.$get('/api/maps/place', {
			params: Object.assign({
				key: ctx.state.merchant.settings.integrations.google_maps.api_token || process.env.GOOGLE_MAPS_API_KEY,
				fields: 'formatted_address,address_component,geometry',
				save: data.save
			}, data)
		})
	},
	getPlaceByGeocode (ctx, data) {
		return this.$axios.$get('/api/maps/geocode', {
			params: Object.assign({
				key: ctx.state.merchant.settings.integrations.google_maps.api_token || process.env.GOOGLE_MAPS_API_KEY,
				save: data.save
			}, data)
		})
	},
	getDistance (ctx, data) {
		return this.$axios.$get('/api/maps/distance', {
			params: Object.assign({
				key: ctx.state.merchant.settings.integrations.google_maps.api_token || process.env.GOOGLE_MAPS_API_KEY,
				language: ctx.state.merchant.settings.general.language_code || null,
				region: ctx.state.merchant.settings.general.country_code
					? `country:${ctx.state.merchant.settings.general.country_code.toLowerCase()}`
					: null
			}, data)
		})
	},
	getNearbyStores (ctx, { slug }) {
		return this.$axios.$get('/api/guest/stores', {
			params: {
				lat: ctx.state.location ? ctx.state.location.coordinates[1] : ctx.state.merchant.settings.general.default_user_location.lat ? ctx.state.merchant.settings.general.default_user_location.lat : null,
				lng: ctx.state.location ? ctx.state.location.coordinates[0] : ctx.state.merchant.settings.general.default_user_location.lng ? ctx.state.merchant.settings.general.default_user_location.lng : null,
				slug,
				location_type: 'store',
				active: true
			}
		}).then((response) => {
			if (response.code === 200) {
				const previousStoreId = ctx.state.selectedStore ? ctx.state.selectedStore.id : null
				let stores = response.data.stores.filter(s => s.available)

				if (ctx.state.merchant?.settings?.app?.appearance?.home_page?.show_nearest_location) {
					stores = stores.sort((a, b) => a.distance - b.distance).slice(0, 1)
				}

				const previousStoreIndex = previousStoreId
					? response.data.stores.findIndex(s => s.id === previousStoreId)
					: -1

				ctx.commit('setState', { key: 'nearbyStores', value: stores })
				ctx.commit('setSelectedStore', stores[
					previousStoreIndex !== -1 ? previousStoreIndex : 0
				] || null)

				if (!ctx.state.selectedStore || ctx.state.selectedStore.id !== previousStoreId) {
					ctx.dispatch('resetCart')
				}
			}

			return response
		})
	},
	getStoreDetails (ctx, id) {
		return this.$axios.$get(`/api/guest/stores/${id}`)
	},
	getItems (ctx, filters = {}) {
		return this.$axios.$get('/api/items', {
			params: {
				store_id: ctx.state.selectedStore ? ctx.state.selectedStore.id : null,
				...filters
			}
		}).then((response) => {
			response.data.items = response.data.items.map((i) => {
				i.variations = i.variations.map(v => (
						{
							...v,
							original_price: v.price
						}
				))

				return {
					...i,
					variation_index: 0,
					selected_attributes: i.variations[0].item_attributes.map(a => ({
						label: a.label,
						slug: a.slug,
						value: a.value
					}))
				}
			})

			return response
		})
	},
	getCustomer (ctx, filters = {}) {
		return this.$axios.$get('/api/auth/customers', {
			params: {
				...filters
			}
		})
	},
	saveCustomer (ctx, cust = {}) {
		return this.$axios.$post('/api/auth/save', cust)
	},
	getCategories (ctx, filters = {}) {
		return this.$axios.$get('/api/guest/categories', {
			params: {
				store_id: ctx.state.selectedStore ? ctx.state.selectedStore.id : null,
				parent_id: 0,
				...filters
			}
		}).then((response) => {
			ctx.commit('setState', {
				key: 'categories',
				value: {
					data: filters.page
						? ctx.state.categories.data.concat(response.data.categories)
						: [{ id: null, name: this.$i18n.t('all items') }].concat(response.data.categories),
						pagination: response.data.pagination
				}
			})
		})
	},
	applyDiscount (ctx) {
		const coupon = ctx.state.appliedCoupon
		let discountQtyArr = []

		if (coupon.prerequisite_items.length && coupon.range.prerequisite) {
			discountQtyArr = ctx.getters.getDiscountQtyArr(coupon.range.prerequisite, 'item')
		} else if (coupon.prerequisite_categories.length && coupon.range.prerequisite) {
			discountQtyArr = ctx.getters.getDiscountQtyArr(coupon.range.prerequisite, 'category')
		}

		if (coupon.items.length) {
			coupon.items.forEach((item) => {
				let quantity = Math.floor(Math.floor((discountQtyArr.length ? (discountQtyArr[0] / discountQtyArr[1]) : 1)) * +coupon.range[item.variations[item.variation_index].id].item_qty)
				const cartItemIndex = ctx.state.cart.items.findIndex(i => i.variations[i.variation_index].id === item.variations[item.variation_index].id)

				if (quantity > parseFloat(coupon.range[item.variations[item.variation_index].id].max_discount_qty)) {
					quantity = parseFloat(coupon.range[item.variations[item.variation_index].id].max_discount_qty)
				}

				if (cartItemIndex !== -1) {
					if (!ctx.state.merchant.settings.order_weight_limit || (
						ctx.state.cart.price.totalQuantity + quantity <= ctx.state.merchant.settings.order_weight_limit)
					) {
						ctx.state.cart.items[cartItemIndex].variations[ctx.state.cart.items[cartItemIndex].variation_index].quantity =
							ctx.state.cart.items[cartItemIndex].variations[ctx.state.cart.items[cartItemIndex].variation_index].quantity -
							(ctx.state.cart.items[cartItemIndex].variations[ctx.state.cart.items[cartItemIndex].variation_index].discounted_quantity || 0) + quantity
						ctx.state.cart.items[cartItemIndex].variations[ctx.state.cart.items[cartItemIndex].variation_index].discounted_quantity = quantity
						ctx.state.cart.price.totalQuantity += quantity
					} else {
						return false
					}
				} else if (!ctx.state.merchant.settings.order_weight_limit || (
					ctx.state.cart.price.totalQuantity + quantity <= ctx.state.merchant.settings.order_weight_limit)
				) {
					item.variations[item.variation_index].quantity = quantity
					item.variations[item.variation_index].discounted_quantity = quantity
					ctx.state.cart.items.push(item)
					ctx.state.cart.price.totalQuantity += quantity
				} else {
					return false
				}
			})
		}
	},
	cartCalculation (ctx) {
		const totalItemDiscount = 0
		let leftOverDiscountRate = 0
		let cartItems = JSON.parse(JSON.stringify(ctx.state.cart.items))
		const coupon = ctx.state.appliedCoupon
		const price = {
			subtotal: 0,
			tax: 0,
			inclusiveTaxTotal: 0,
			exclusiveTaxTotal: 0,
			taxes: {},
			discount: 0,
			discountedAmount: 0,
			discountedTax: 0,
			charge: 0,
			charges: [],
			roundOff: 0,
			total: 0,
			totalQuantity: 0
		}

		const calculateTax = (variation) => {
			let totalTax = 0

			variation.computedTaxes = []
			variation.taxes.forEach((tax, index) => {
				const taxData = {
					id: tax.id,
					name: tax.name,
					rate: tax.rate,
					type: tax.type,
					discounted_tax: variation.discountedTax
				}

				taxData.amount = this.$transformNumber((variation.subtotal - variation.discountedAmount) * (tax.rate / 100))
				totalTax += taxData.amount

				if (index === variation.taxes.length - 1 && variation.taxes[0].type === 'inclusive') {
					const deviation = this.$transformNumber((variation.totalInclusiveTax - totalTax))

					if ([-0.01, -0.001, 0.01, 0.001].includes(deviation)) {
						taxData.amount += deviation
					}
				}

				variation.computedTaxes.push({ ...taxData })
				variation.taxAmount = this.$transformNumber(variation.taxAmount + taxData.amount)
				price.tax = this.$transformNumber(price.tax + taxData.amount)

				if (tax.type === 'additive') {
					price.exclusiveTaxTotal = this.$transformNumber(price.exclusiveTaxTotal + taxData.amount)
				} else {
					price.inclusiveTaxTotal = this.$transformNumber(price.inclusiveTaxTotal + taxData.amount)
				}

				if (price.taxes[taxData.id]) {
					price.taxes[taxData.id].amount = this.$transformNumber(price.taxes[taxData.id].amount + taxData.amount)
					price.taxes[taxData.id].discounted_tax = this.$transformNumber(price.taxes[taxData.id].discounted_tax + taxData.discounted_tax)
				} else {
					price.taxes[taxData.id] = taxData
				}
			})
		}

		cartItems = cartItems.map((item) => {
			const variation = item.variations[item.variation_index]
			const total = this.$transformNumber(variation.price * variation.quantity)

			variation.subtotal = total
			variation.taxAmount = 0
			variation.discountedAmount = 0
			variation.discountedTax = 0
			variation.discount = 0
			price.totalQuantity += variation.quantity
			variation.discounted_quantity = ctx.state.appliedCoupon ? variation.discounted_quantity : 0

			if (variation.taxes[0]?.type === 'inclusive') {
				const totalTaxRate = variation.taxes.reduce((sum, t) => {
					sum += t.rate

					return sum
				}, 0)

				variation.totalInclusiveTax = this.$transformNumber((total * (totalTaxRate / (100 + totalTaxRate))))
				variation.subtotal = this.$transformNumber(total - variation.totalInclusiveTax)
				variation.taxType = 'inclusive'
			} else {
				variation.taxType = 'exclusive'
			}

			price.subtotal = this.$transformNumber(price.subtotal + variation.subtotal)

			if (
				!ctx.state.merchant.settings.general.tax_calculation_phase ||
				ctx.state.merchant.settings.general.tax_calculation_phase === 'before_discount'
			) {
				calculateTax(variation)
			}

			return item
		})

		if (coupon) {
			if (coupon.discount_entity === 'loyalty') {
				const total = price.subtotal + price.tax
				let eligibleDiscountAmount = coupon.loyalty.amount_per_point * coupon.loyalty.reward_points

				if (coupon.discount_type === 'percentage') {
					coupon.discount_value = total * (coupon.loyalty.discount_value / 100)
					coupon.discount_value = coupon.discount_value > coupon.max_discount_value
						? coupon.max_discount_value
						: coupon.discount_value
				} else if (coupon.discount_type === 'fixed' && coupon.loyalty.discount_value > total) {
					coupon.discount_value = total
				}

				eligibleDiscountAmount = coupon.discount_value > eligibleDiscountAmount
					? eligibleDiscountAmount
					: coupon.discount_value
				coupon.loyalty.redeemable_points = Math.floor(eligibleDiscountAmount / coupon.loyalty.amount_per_point)

				if (coupon.discount_type === 'percentage') {
					coupon.discount_value = ((coupon.loyalty.redeemable_points * coupon.loyalty.amount_per_point) / total) * 100
				} else if (coupon.discount_type === 'fixed') {
					coupon.discount_value = coupon.loyalty.redeemable_points * coupon.loyalty.amount_per_point
				}
			}

			if (this.app.validateDiscount(coupon, {
				items: cartItems,
				price
			}) && coupon.discount_entity !== 'shipping') {
				cartItems = cartItems.map((item) => {
					const variation = item.variations[item.variation_index]
					let discountedAmount = 0
					let discountedTax = 0
					let discountAmountRate = 0
					let taxes = []
					const discountData = coupon.range[item.variations[item.variation_index].id] ||
						coupon.range[item.variations[item.variation_index].category_id] ||
						{
							discount_type: coupon.discount_type,
							discount_value: coupon.discount_value,
							max_discount_value: coupon.max_discount_value
						}

					const buyQty = coupon.range.prerequisite &&
						coupon.range.prerequisite[item.variations[item.variation_index].id]?.qty

					if (buyQty) {
						discountData.buy_item_qty = Object.values(buyQty)[0]
					}

					if (this.app.validateDiscount(coupon, {
						items: cartItems,
						price
					}) && (
						coupon.items.length === 0 || coupon.items.map(i => i.variations[i.variation_index].id).includes(variation.id)
					) && (
						coupon.categories.length === 0 || (
							coupon.categories.find(category => category.id === variation.category_id) && (
								coupon.range.prerequisite[variation.category_id]?.excluded_items || []
							).findIndex(ei => +ei.id === variation.id) === -1
						)
					) && (!discountData.max_discount_value || (price.discount < discountData.max_discount_value))) {
						if (discountData.discount_type === 'percentage') {
							discountedAmount += variation.subtotal > 0
								? ((
									(variation.subtotal - variation.discountedAmount) / variation.quantity
								) * (
									variation.discounted_quantity >= variation.quantity
										? variation.quantity
										: variation.discounted_quantity || (
											discountData.item_qty === 0 && discountData.buy_item_qty > 0
												? Math.floor(
													variation.quantity / discountData.buy_item_qty
												) * discountData.buy_item_qty
												: variation.quantity
										)
								)) * (discountData.discount_value / 100)
								: 0

								if (ctx.state.merchant.settings.general.tax_calculation_phase === 'before_discount') {
									discountedTax += variation.taxAmount > 0
										? ((
											(variation.taxAmount - variation.discountedTax) / variation.quantity
										) * (
											variation.discounted_quantity >= variation.quantity
												? variation.quantity
												: variation.discounted_quantity || (
													discountData.item_qty === 0 && discountData.buy_item_qty > 0
														? Math.floor(
															variation.quantity / discountData.buy_item_qty
														) * discountData.buy_item_qty
														: variation.quantity
												)
										)) * (discountData.discount_value / 100)
										: 0
								}
						} else if (discountData.discount_type === 'fixed') {
							const total = price.subtotal + (ctx.state.merchant.settings.general.tax_calculation_phase === 'before_discount' ? price.tax : 0)
							const variationTotal = variation.subtotal + (ctx.state.merchant.settings.general.tax_calculation_phase === 'before_discount' ? variation.taxAmount : 0)

							if (discountData.discount_value < total) {
								discountAmountRate = ['dynamic', 'item', 'category'].includes(coupon.discount_entity) ? 1 : (variationTotal / total) + leftOverDiscountRate
								const totalDiscountedAmount = discountData.discount_value * discountAmountRate

								discountedAmount += variation.subtotal / variationTotal * totalDiscountedAmount

								if (ctx.state.merchant.settings.general.tax_calculation_phase === 'before_discount') {
									discountedTax += variation.taxAmount / variationTotal * totalDiscountedAmount
								}
							} else {
								discountedAmount += variation.subtotal - variation.discountedAmount

								if (ctx.state.merchant.settings.general.tax_calculation_phase === 'before_discount') {
									discountedTax += variation.taxAmount - variation.discountedTax
								}
							}
						}

						if ((price.discount - totalItemDiscount) <= discountData.max_discount_value) {
							const totalDiscount = discountedAmount + discountedTax

							if (discountData.max_discount_value > 0 && ((
								(price.discount - totalItemDiscount) + totalDiscount
							) > discountData.max_discount_value)) {
								const availableDiscountValue = this.$transformNumber(discountData.max_discount_value - (price.discount - totalItemDiscount))

								discountedAmount = availableDiscountValue * (discountedAmount / totalDiscount)
								discountedTax = availableDiscountValue * (discountedTax / totalDiscount)
							}
						}

						if (ctx.state.merchant.settings.general.tax_calculation_phase === 'before_discount') {
							taxes = variation.computedTaxes.map((tax) => {
								tax.discounted_tax = variation.taxAmount > 0
								? this.$transformNumber(tax.discounted_tax + ((tax.amount / variation.taxAmount) * discountedTax))
								: 0
								price.taxes[tax.id].discounted_tax += tax.discounted_tax

								return tax
							})
						}

						if (discountedAmount > variation.subtotal) {
							discountedTax = discountedAmount - variation.subtotal
							discountedAmount -= discountedTax
						}

						if (variation.discountedAmount <= (variation.subtotal + (ctx.state.merchant.settings.general.tax_calculation_phase === 'after_discount' ? 0 : variation.taxAmount))) {
							variation.discountedAmount = this.$transformNumber(variation.discountedAmount + discountedAmount)
							variation.discountedTax = this.$transformNumber(variation.discountedTax + discountedTax)
							variation.discount = this.$transformNumber(variation.discount + discountedAmount + discountedTax)
							price.discountedAmount = this.$transformNumber(price.discountedAmount + discountedAmount)
							price.discountedTax = this.$transformNumber(price.discountedTax + discountedTax)
							price.discount = this.$transformNumber(price.discount + discountedAmount + discountedTax)

							if (taxes.length) {
								variation.taxes = taxes
							}

							if (leftOverDiscountRate) {
								leftOverDiscountRate = 0
							}
						} else if (discountData.discount_type === 'fixed' && coupon.items.length === 0) {
							leftOverDiscountRate += discountAmountRate
						}
					}

					if (ctx.state.merchant.settings.general.tax_calculation_phase === 'after_discount') {
						if (variation.discountedAmount) {
							const totalTaxRate = variation.taxes.reduce((sum, t) => {
								sum += t.rate

								return sum
							}, 0)

							variation.totalInclusiveTax = this.$transformNumber(((variation.subtotal - variation.discountedAmount) * (totalTaxRate / 100)))
						}

						calculateTax(variation)
					}

					return item
				})
			}
		} else if (ctx.state.merchant.settings.general.tax_calculation_phase === 'after_discount') {
			cartItems = cartItems.map((item) => {
				calculateTax(item.variations[item.variation_index])

				return item
			})
		}

		price.total = this.$transformNumber(price.subtotal + price.tax - price.discount)
		ctx.state.merchant.charges.forEach((charge) => {
			if (charge.slug === 'delivery_charge' && ctx.state.selectedOrderType && ctx.state.selectedOrderType.slug !== 'delivery') {
				return
			}

			if ((
				charge.stores.length === 0 || charge.stores.some(store => store.id === ctx.state.selectedStore.id)
			) && this.app.validateConditions(price, charge.conditions)) {
				let amount = 0

				if (charge.type === 'fixed') {
					amount = this.$transformNumber(charge.value)
				} else if (charge.type === 'percentage' && price[charge.applicable_on]) {
					amount = this.$transformNumber(price[charge.applicable_on] * (Math.abs(charge.value) / 100))
				}

				if (charge.slug === 'delivery_charge' && Object.keys(charge.additional).length &&
					ctx.state.deliveryAddress && ctx.state.deliveryAddress.distance.value > charge.additional.base) {
						amount = this.$transformNumber(amount + parseFloat((
							(Math[charge.additional.round_up ? 'ceil' : 'floor'](ctx.state.deliveryAddress.distance.value / 1000)) -
							((charge.value || charge.additional.is_free_shipping) ? (charge.additional.base / 1000) : 0)
						) * (charge.additional.value / charge.additional.multiplier)))
				}

				if (amount > 0) {
					let inclusivePercent = 0
					let exclusivePercent = 0
					let chargeTax = 0

					charge.taxes.forEach((tax, i) => {
						if (tax.inclusion_type === 'inclusive') {
							inclusivePercent = this.$transformNumber(inclusivePercent + tax.rate)
						} else {
							exclusivePercent = this.$transformNumber(exclusivePercent + tax.rate)
							charge.taxes[i].tax_amount = this.$transformNumber(amount * (tax.rate / 100))
							charge.taxes[i].discounted_tax = 0
						}
					})

					if (exclusivePercent > 0) {
						chargeTax = this.$transformNumber(amount * (exclusivePercent / 100))
						price.exclusiveTaxTotal = this.$transformNumber(price.exclusiveTaxTotal + chargeTax)
					} else if (inclusivePercent > 0) {
						amount = this.$transformNumber(amount - amount * (inclusivePercent / (100 + inclusivePercent)))
						charge.taxes.forEach((tax, i) => {
							charge.taxes[i].tax_amount = this.$transformNumber(amount * (tax.rate / 100))
							charge.taxes[i].discounted_tax = 0
							chargeTax = this.$transformNumber(chargeTax + charge.taxes[i].tax_amount)
							price.inclusiveTaxTotal = this.$transformNumber(price.inclusiveTaxTotal + charge.taxes[i].tax_amount)
						})
					}

					price.charge = this.$transformNumber(price.charge + amount)
					price.tax = this.$transformNumber(price.tax + chargeTax)
					price.total = this.$transformNumber(price.total + amount + chargeTax)
					price.charges.push(Object.assign({ amount }, charge))

					if (charge.slug === 'delivery_charge' && coupon && coupon.discount_entity === 'shipping' &&
					this.app.validateDiscount(coupon, {
						items: cartItems,
						price
					})) {
						if (coupon.discount_type === 'percentage') {
							price.discount = this.$transformNumber(Math.min(amount * (coupon.discount_value / 100), coupon.max_discount_value))
						} else if (coupon.discount_type === 'fixed') {
							price.discount = this.$transformNumber(Math.min(amount, coupon.discount_value, coupon.max_discount_value))
						}

						price.total = this.$transformNumber(price.total - price.discount)
					}
				}
			}
		})

		price.taxes = Object.values(price.taxes)

		if (ctx.state.merchant.settings.general.round_off_total) {
			const roundedTotal = Math.round(price.total)

			price.roundOff = this.$transformNumber(parseFloat(roundedTotal - price.total))
			price.total = this.$transformNumber(roundedTotal)
		}

		ctx.commit('setState', {
			key: 'cart',
			value: {
				store: ctx.state.cart.store,
				items: cartItems,
				price
			}
		})
	},
	getDeliverySlots (ctx) {
		return this.$axios.$get(`/api/guest/stores/${ctx.state.cart.store.id}/slots`)
	},
	getAddresses (ctx, data = null) {
		return this.$axios.$get('/api/addresses', {
			params: data
		})
	},
	getPaymentMethods (ctx) {
		return this.$axios.$get('/api/payments/methods').then((response) => {
			if (ctx.state.user.is_internal_user) {
				response.data.payment_methods = response.data.payment_methods.filter(pm => pm.slug === 'cod')
			}

			return response
		})
	},
	saveAddress (ctx, address) {
		let url = '/api/addresses'

		if (address.id) {
			url += `/${address.id}`
		}

		const custId = address.customer_id ? address.customer_id : ''

		delete address.customer_id

		return this.$axios[address.id ? '$put' : '$post'](url, address, {
			params: {
				lat: ctx.state.cart.store ? ctx.state.cart.store.address.coordinates[1] : null,
				lng: ctx.state.cart.store ? ctx.state.cart.store.address.coordinates[0] : null,
				customer_id: custId
			}
		})
	},
	deleteAddress (ctx, id) {
		return this.$axios.$delete(`/api/addresses/${id}`)
	},
	deleteAccount () {
		return this.$axios.$delete('/api/auth/customer')
	},
	completeOrder (ctx, selectedCustomer) {
		const date = new Date()
		const customAttributes = {}

		if (ctx.state.merchant.settings.general.tax_calculation_phase) {
			customAttributes.tax_calculation_phase = ctx.state.merchant.settings.general.tax_calculation_phase
		}

		const order = {
			store_id: ctx.state.cart.store.id,
			payment_method: ctx.state.paymentMethod,
			customer: {
				id: selectedCustomer?.selectedCustomer?._id || ctx.state.user.id,
				email: selectedCustomer?.selectedCustomer?.email || ctx.state.user.email,
				name: selectedCustomer?.selectedCustomer?.name || ctx.state.user.name,
				phone: selectedCustomer?.selectedCustomer?.phone || ctx.state.user.phone
			},
			instructions: '',
			shipping_address: ctx.state.deliveryAddress || {},
			billing_address: ctx.state.deliveryAddress || {},
			sub_total: ctx.state.cart.price.subtotal,
			tax: ctx.state.cart.price.tax,
			inclusive_tax: ctx.state.cart.price.inclusiveTaxTotal,
			additive_tax: ctx.state.cart.price.exclusiveTaxTotal,
			discount: ctx.state.cart.price.discount,
			order_discount: ctx.state.cart.price.discount > 0
				? {
					discount_id: ctx.state.appliedCoupon.id,
					code: ctx.state.appliedCoupon.code,
					name: ctx.state.appliedCoupon.name,
					amount: ctx.state.cart.price.discountedAmount,
					tax: ctx.state.cart.price.discountedTax
				}
				: {},
			charge: ctx.state.cart.price.charge,
			charges: ctx.state.cart.price.charges,
			round_off_amount: ctx.state.cart.price.roundOff,
			total: ctx.state.cart.price.total,
			order_type: ctx.state.selectedOrderType.slug,
			order_date: this.$moment(date).utc().format('YYYY-MM-DD HH:mm:ss'),
			scheduled_at: ctx.state.deliverySlot ? ctx.state.deliverySlot.start_datetime : null,
			custom_attributes: customAttributes,
			items: ctx.state.cart.items.reduce((items, item) => {
				const variation = item.variations[item.variation_index]

				if (variation.quantity > 0) {
					const customAttributes = {
						notes: variation.notes || null
					}

					items.push({
						item_id: variation.id,
						item_name: variation.name,
						qty: variation.quantity,
						unit_cost: this.$transformNumber(variation.price),
						mrp: variation.mrp,
						alt_lang: variation.alt_lang,
						food_type: variation.food_type,
						sub_total: variation.subtotal,
						tax: variation.taxAmount,
						taxes: variation.computedTaxes,
						discount: variation.discount,
						item_discount: variation.discount > 0
							? {
								discount_id: ctx.state.appliedCoupon.id,
								code: ctx.state.appliedCoupon.code,
								name: ctx.state.appliedCoupon.name,
								amount: variation.discountedAmount,
								tax: variation.discountedTax
							}
							: {},
						total: this.$transformNumber(variation.subtotal + variation.taxAmount - variation.discount),
						combos: variation.combos.length
							? variation.combos[0].items.map(i => ({
								group_id: variation.combos[0].id,
								item_id: i.id,
								item_name: i.name,
								price: i.price,
								qty: 1
							}))
							: [],
						modifiers: variation.selected_modifiers || [],
						custom_attributes: customAttributes
					})
				}

				return items
			}, [])
		}

		return this.$axios.$post('/api/orders', order)
	},
	updateOrderStatus (ctx, data) {
		const orderId = data.id

		delete data.id

		return this.$axios.$patch(`/api/orders/${orderId}/status`, data)
	},
	patchOrder (ctx, data) {
		const orderId = data.id

		delete data.id

		return this.$axios.$patch(`/api/orders/${orderId}`, data)
	},
	resetCart (ctx) {
		ctx.commit('resetCart')
		ctx.commit('setState', { key: 'selectedOrderType', value: null })
		ctx.commit('setState', { key: 'deliverySlot', value: null })
		ctx.commit('setState', { key: 'appliedCoupon', value: null })
		ctx.commit('setState', { key: 'deliveryAddress', value: null })
		ctx.commit('setState', { key: 'paymentMethod', value: null })
	},
	getOrders (ctx, data) {
		return this.$axios.$get('/api/orders', {
			params: data
		})
	},
	getOrderDetails (ctx, id) {
		return this.$axios.$get(`/api/orders/${id}`)
	},
	addToFavorites (ctx, itemId) {
		return this.$axios.$post('/api/items/favorites', {
			item_id: itemId
		})
	},
	removeFavorite (ctx, itemId) {
		return this.$axios.$delete(`/api/items/favorites/${itemId}`)
	},
	getItemStores (ctx, data) {
		return this.$axios.$get('/api/items/stores', {
			params: data
		})
	},
	updateProfile (ctx, { data, filters }) {
		return this.$axios.$patch('/api/auth/profile', data, {
			params: {
				...filters
			}
		}).then((response) => {
			if (response.code === 200) {
				ctx.commit('setUser', response.data.info)
			}

			return response
		})
	},
	getMaster (ctx, data) {
		return this.$axios.$get('/api/masters', {
			params: data
		})
	},
	createConversation (ctx, data) {
		return this.$axios.$post('/api/conversations', data)
	},
	getMessages (ctx, data) {
		return this.$axios.$get(`/api/conversations/${data.conversation_id}/messages`, {
			params: data
		})
	},
	createMessage (ctx, data) {
		return this.$axios.$post(`/api/conversations/${data.conversation_id}/messages`, data)
	},
	getPages (ctx, data) {
		return this.$axios.$get('/api/pages', {
			params: Object.assign({}, {
				active: true
			}, data)
		})
	},
	getDiscounts (ctx, data) {
		return this.$axios.$get('/api/discounts', {
			params: data
		}).then((response) => {
			response.data.discounts = response.data.discounts.map((discount) => {
				return {
					...discount,
					items: discount.items.map((i) => {
						return {
							...i,
							variation_index: 0,
							selected_attributes: i.variations[0].item_attributes.map(a => ({
								label: a.label,
								slug: a.slug,
								value: a.value
							}))
						}
					})
				}
			})

			return response
		})
	},
	applyCoupon (ctx, data) {
		return this.$axios.$post('/api/discounts/apply', data)
	},
	generatePgRequest (ctx, data) {
		return this.$axios.$post(`/api/payments/${ctx.state.paymentMethod}/generate`, data)
	},
	generateCredopayRequest (ctx, data) {
		return this.$axios.$post('/api/payments/credopay/generate', data)
	},
	initPaytabs (ctx, data) {
		return this.$axios.$post('/api/payments/paytabs/init', data)
	},
	checkPaymentStatus (ctx, data) {
		return this.$axios.$get('/api/payments/status', {
			params: data
		})
	},
	checkPgStatus (ctx, data) {
		return this.$axios.$post('/api/payments/wl/pg/status', data)
	},
	removeDiscount (ctx) {
		ctx.commit('setState', { key: 'appliedCoupon', value: null })
		ctx.dispatch('cartCalculation')
	},
	checkDeliveryAvailability (ctx, data) {
		return this.$axios.$get('/api/delivery/availability-check', {
			params: data
		})
	},
	getDeliveryDetails (ctx, data) {
		return this.$axios.$get('/api/delivery/order-delivery-status', {
			params: data
		})
	},
	submitFeedback (ctx, data) {
		return this.$axios.$post('/api/orders/feedback', data)
	}
}

export default actions
