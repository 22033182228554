<template>
	<div>
		<b-navbar
			fixed="top"
			type="dark"
			:variant="$route.name === 'index' ? 'transparent' : 'primary'"
			:class="$route.name !== 'index' ? 'shadow' : ''"
			style="min-height: 3rem"
		>
			<b-navbar-brand
				to="/"
				class="d-flex align-items-center"
			>
				<img
					v-if="merchant.settings.general.logo_img_url"
					:src="merchant.settings.general.logo_img_url"
					width="30"
					height="30"
					class="d-inline-block align-top mr-2"
					:alt="merchant.business.name"
					loading="lazy"
				>
				<b-avatar
					v-else
					:variant="$route.name === 'index' ? 'primary' : 'light'"
					:text="merchant.business.name.slice(0,1)"
					:class="`mr-2 ${$route.name === 'index' ? 'text-light' : 'text-primary'}`"
				/>
				<p class="mb-0">
					{{ merchant.business.name }}
				</p>
			</b-navbar-brand>
			<b-navbar-nav>
				<b-nav-item
					v-if="!showLocationSearch"
					id="current-location"
					href="#"
					class="underline text-capitalize"
					link-classes="px-0"
					@click.self="showLocationSearch = true"
				>
					<b-icon-geo />
					{{ location ? (location.area || location.city || (
						location.line ? location.line.split(', ')[0] : $t('unnamed location')
					)) : $t('set location') }}
				</b-nav-item>
				<b-nav-form v-else>
					<location
						ref="locationComponent"
						v-observe-visibility="focusLocationSearch"
						:custom-style="{ width: '400px' }"
						:close="true"
						:placeholder="$t('enterDeliveryLocation')"
						:save="true"
						@location="reload"
						@hide="showLocationSearch = false"
					/>
				</b-nav-form>
			</b-navbar-nav>
			<b-navbar-nav class="align-items-center ml-auto">
				<b-nav-item
					id="cart-nav-item"
					class="position-relative"
					:class="{
						'd-none': $nuxt.$route.name === 'checkout'
					}"
				>
					<b-icon-cart3 />
					<span
						v-if="cart.items.length"
						class="position-absolute text-center bg-danger"
						:style="{
							top: 0,
							right: 0,
							height: '16px',
							width: '16px',
							borderRadius: '50%',
							fontSize: '10px'
						}"
					>{{ cart.items.length }}</span>
				</b-nav-item>
				<b-nav-item-dropdown
					v-if="user"
					:text="user.name"
					right
				>
					<b-dropdown-item
						v-if="!user.is_internal_user"
						to="/account"
						class="text-capitalize"
					>
						<b-icon-person class="mr-3" />
						{{ $t('profile') }}
					</b-dropdown-item>
					<b-dropdown-item to="/account/favorites" class="text-capitalize">
						<b-icon-heart class="mr-3" />
						{{ $tc('favorite', 2) }}
					</b-dropdown-item>
					<b-dropdown-item v-if="!user.is_internal_user" to="/account/addresses" class="text-capitalize">
						<b-icon-geo class="mr-3" />
						{{ $tc('address', 2) }}
					</b-dropdown-item>
					<b-dropdown-item to="/account/orders" class="text-capitalize">
						<b-icon-box-seam class="mr-3" />
						{{ $tc('order', 2) }}
					</b-dropdown-item>
					<b-dropdown-item v-if="merchant.subscription.products && merchant.subscription.products.referral && user.referral_code && !user.is_internal_user" v-b-modal.referrals class="text-capitalize">
						<b-icon-people class="mr-3" />
						{{ $tc('referral', 2) }}
					</b-dropdown-item>
					<b-dropdown-divider />
					<b-dropdown-item class="text-capitalize" @click="logout">
						<b-icon-power class="mr-3" />
						{{ $t('logout') }}
					</b-dropdown-item>
				</b-nav-item-dropdown>
				<b-nav-item v-if="!user" v-b-toggle.auth class="text-capitalize" @click="authComponent = 'login'">
					{{ $t('login') }}
				</b-nav-item>
				<b-nav-item v-if="!user" v-b-toggle.auth @click="authComponent = 'signup'">
					{{ $t('signup') | capitalize }}
				</b-nav-item>
			</b-navbar-nav>
		</b-navbar>
		<b-modal
			v-if="merchant.subscription.products && merchant.subscription.products.referral && user && user.referral_code"
			id="referrals"
			:title="$t('inviteYourFriend')"
			centered
			hide-footer
			@hidden="referralCodeIcon = 'clipboard'"
		>
			<b-img-lazy
				src="/images/referral.jpg"
				height="200"
				width="400"
				class="mb-3"
				blank
				blank-color="#ccc"
				center
			/>
			<b-form-group :description="$t('shareCodeWithFriends')">
				<template #label>
					<span class="text-capitalize">{{ user.name }}</span>, {{ $t('yourReferralCode') }}
				</template>
				<b-input-group>
					<b-input-group-prepend is-text>
						<b-icon :icon="referralCodeIcon" />
					</b-input-group-prepend>
					<b-form-input ref="referralCode" :value="user.referral_code" readonly />
					<b-input-group-append>
						<b-button variant="success" class="text-capitalize" @click="copyToClipboard">
							{{ $t('copy') }}
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
			<p class="line-with-words">
				<span class="bg-white">{{ $t('shareIt') }}</span>
			</p>
			<b-form-row>
				<b-col>
					<b-button
						variant="outline-whatsapp"
						class="d-flex align-items-center justify-content-center text-capitalize"
						block
						size="sm"
						@click="shareReferralCode('whatsapp')"
					>
						<whatsapp-icon class="mr-1" style="height: 20px; width: 20px" />
						{{ $t('whatsapp') }}
					</b-button>
				</b-col>
				<b-col>
					<b-button
						variant="outline-facebook"
						class="text-capitalize"
						block
						size="sm"
						@click="shareReferralCode('facebook')"
					>
						<b-icon-facebook class="mr-1" />
						{{ $t('fb') }}
					</b-button>
				</b-col>
				<b-col>
					<b-button
						variant="outline-twitter"
						class="text-capitalize"
						block
						size="sm"
						@click="shareReferralCode('twitter')"
					>
						<b-icon-twitter class="mr-1" />
						{{ $t('twitter') }}
					</b-button>
				</b-col>
			</b-form-row>
		</b-modal>
		<attributes :show="showAttributes" @close="showAttributes = false" />
		<modifiers :show="showModifiers" @close="showModifiers = false" />
		<b-popover
			id="cart-popover"
			target="cart-nav-item"
			triggers="hover"
			placement="bottomleft"
		>
			<template #title>
				<div class="d-flex justify-content-between align-items-center">
					<div>
						<p class="text-capitalize mb-0">
							{{ $t('cart') }}
						</p>
						<small v-if="cart.store" class="text-capitalize text-muted">{{ cart.store.name }}</small>
					</div>
					<small class="text-capitalize text-muted">
						{{ cart.items.length }} {{ $tc('item', cart.items.length > 1 ? 2 : 1) }}
					</small>
				</div>
			</template>
			<div v-if="cart.items.length">
				<div class="p-3">
					<b-row
						v-for="(item, index) in cart.items"
						:key="index"
						class="align-items-center"
						:class="{
							'mb-2': index < cart.items.length - 1
						}"
						style="line-height: 1.2"
						no-gutters
					>
						<b-col md="7" class="text-capitalize">
							<p class="mb-0">
								<span>{{ locale == 'en' ? item.variations[item.variation_index].name : (item.variations[item.variation_index]?.alt_lang || item.variations[item.variation_index].name) }}</span>
							</p>
							<small v-if="item.variations[item.variation_index].combos.length" class="d-block">
								{{ item.variations[item.variation_index].combos[0].name }}: {{ item.variations[item.variation_index].combos[0].items.map(i => i.name).join(', ') }}
							</small>
							<small v-if="item.variations[item.variation_index].selected_modifiers && item.variations[item.variation_index].selected_modifiers.length">
								{{ $t('with') | capitalize }} {{ item.variations[item.variation_index].selected_modifiers.map(m => m.item_name).join(', ') }}
							</small>
						</b-col>
						<b-col md="2" class="text-center">
							x{{ item.variations[item.variation_index].quantity }}
						</b-col>
						<b-col md="3" class="text-right">
							{{ toCurrency(item.variations[item.variation_index].price * item.variations[item.variation_index].quantity) }}
						</b-col>
					</b-row>
				</div>
				<div
					class="position-sticky bg-white shadow-top rounded p-3"
					:style="{
						left: 0,
						right: 0,
						bottom: 0,
						zIndex: 3
					}"
				>
					<div class="d-flex justify-content-between align-items-center">
						<p class="text-capitalize mb-0">
							{{ $t('subtotal') }}
						</p>
						<p class="mb-0">
							{{ toCurrency(cart.price.subtotal) }}
						</p>
					</div>
					<div v-if="cart.price.tax" class="d-flex justify-content-between align-items-center">
						<p class="text-capitalize mb-0">
							{{ $t('tax') }}
						</p>
						<p class="mb-0">
							{{ toCurrency(cart.price.tax) }}
						</p>
					</div>
					<div class="d-flex justify-content-between align-items-center">
						<h5 class="text-capitalize mb-0">
							{{ $t('total') }}
						</h5>
						<h5 class="mb-0">
							{{ toCurrency(cart.price.subtotal + cart.price.tax) }}
						</h5>
					</div>
					<small class="text-muted">{{ $t('noteExtra') }}</small>
					<hr>
					<b-button
						variant="success"
						class="text-uppercase"
						block
						size="sm"
						@click="checkout"
					>
						{{ $t('checkout') }}
					</b-button>
				</div>
			</div>
			<div
				v-else
				class="h-100 d-flex justify-content-center align-items-center p-3"
				style="flex-direction: column"
			>
				<img src="/images/empty-cart.svg" height="120px" class="mb-2">
				<h5>
					{{ $t('cartEmpty') }}
				</h5>
			</div>
		</b-popover>
		<auth />
		<nuxt />
		<div
			ref="viewer"
			v-viewer="{
				title: 0,
				toolbar: {
					zoomIn: 1,
					zoomOut: 1,
					oneToOne: 0,
					reset: 1,
					prev: 1,
					play: 0,
					next: 1,
					rotateLeft: 0,
					rotateRight: 0,
					flipHorizontal: 0,
					flipVertical: 0
				}
			}"
			class="d-none"
		>
			<img v-for="src in images" :key="src" :src="src">
		</div>
		<footer class="bg-primary p-3">
			<b-row>
				<b-col md="4">
					<a
						class="d-flex align-items-center text-white text-decoration-none mb-3"
						:href="merchant.business.website || '/'"
						:target="merchant.business.website ? '_blank' : '_self'"
					>
						<img class="mr-3" :src="merchant.settings.general.logo_img_url" height="50">
						<p class="mb-0">
							{{ merchant.business.name }}
						</p>
					</a>
					<p class="text-white mb-0">
						<span v-if="merchant.business.address.line">{{ merchant.business.address.line }},</span>
						<span v-if="merchant.business.address.area">{{ merchant.business.address.area }},</span>
						<span v-if="merchant.business.address.city">{{ merchant.business.address.city }},</span>
						<span v-if="merchant.business.address.zip">{{ merchant.business.address.zip }}</span>
					</p>
					<p v-if="merchant.settings.general.support && merchant.settings.general.support.support_phone" class="text-white mb-0">
						<b-icon-phone class="mr-1" />
						<a
							class="text-white"
							:href="`tel:${merchant.settings.general.support.support_phone}`"
						>{{ merchant.settings.general.support.support_phone }}</a>
					</p>
					<p v-if="merchant.settings.general.support && merchant.settings.general.support.whatsapp_phone" class="text-white mb-0">
						<whatsapp-icon class="mr-1" style="height: 16px; width: 16px" />
						<a
							class="text-white"
							target="_blank"
							:href="`https://web.whatsapp.com/send?phone=${merchant.settings.general.support.whatsapp_phone}]}`"
						> {{ merchant.settings.general.support.whatsapp_phone }}</a>
					</p>
					<p v-if="merchant.settings.general.support && merchant.settings.general.support.email" class="text-white mb-0">
						<b-icon-at class="mr-1" />
						<a class="text-white" :href="`mailto:${merchant.settings.general.support.email}`">
							{{ merchant.settings.general.support.email }}
						</a>
					</p>
				</b-col>
				<b-col md="3">
					<h5 class="text-white text-uppercase">
						{{ $t('help') }}
					</h5>
					<b-nav vertical>
						<b-nav-item to="/help/about-us" link-classes="text-white text-capitalize px-0">
							{{ $t('about us') }}
						</b-nav-item>
						<b-nav-item to="/help/faq" link-classes="text-white text-capitalize px-0">
							{{ $tc('faq', 2) }}
						</b-nav-item>
					</b-nav>
				</b-col>
				<b-col md="3">
					<h5 class="text-uppercase text-white">
						{{ $t('legal') }}
					</h5>
					<b-nav vertical>
						<b-nav-item to="/legal/terms-conditions" link-classes="text-capitalize text-white px-0">
							{{ $t('t&c') }}
						</b-nav-item>
						<b-nav-item to="/legal/refund-cancellation" link-classes="text-capitalize text-white px-0">
							{{ $t('r&c') }}
						</b-nav-item>
						<b-nav-item to="/legal/privacy-policy" link-classes="text-capitalize text-white px-0">
							{{ $t('privacy policy') }}
						</b-nav-item>
					</b-nav>
				</b-col>
				<b-col md="2">
					<a
						v-if="merchant.settings.app.app_store_links.apple_app_store_url"
						:href="merchant.settings.app.app_store_links.apple_app_store_url"
						target="_blank"
						class="float-right"
					>
						<img src="/images/apple-store.png" width="200px" class="mb-3">
					</a>
					<a
						v-if="merchant.settings.app.app_store_links.google_play_store_url"
						:href="merchant.settings.app.app_store_links.google_play_store_url"
						target="_blank"
						class="float-right"
					>
						<img src="/images/play-store.png" width="200px" class="mb-3">
					</a>
				</b-col>
			</b-row>
			<hr>
			<div class="d-flex justify-content-between align-items-center">
				<locale class="w-auto mr-2" />
				<div class="text-white text-center">
					<p class="mb-0">
						&copy; {{ $t('allRightsReserved', [new Date().getFullYear(), merchant.business.name]) }}
					</p>
				</div>
				<div>
					<a
						v-if="merchant.settings.general.social_media_pages.facebook_page_url"
						:href="merchant.settings.general.social_media_pages.facebook_page_url"
						target="_blank"
						:title="$t('followUsOnFacebook')"
						class="text-white mr-3"
					>
						<b-icon-facebook style="height: 24px; width: 24px" />
					</a>
					<a
						v-if="merchant.settings.general.social_media_pages.instagram_page_url"
						:href="merchant.settings.general.social_media_pages.instagram_page_url"
						target="_blank"
						:title="$t('followUsOnInstagram')"
						class="text-white mr-3"
					>
						<b-icon-instagram style="height: 24px; width: 24px" />
					</a>
					<a
						v-if="merchant.settings.general.social_media_pages.twitter_page_url"
						:href="merchant.settings.general.social_media_pages.twitter_page_url"
						target="_blank"
						:title="$t('followUsOnTwitter')"
						class="text-white mr-3"
					>
						<b-icon-twitter style="height: 24px; width: 24px" />
					</a>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	import Modifiers from '@/components/modifiers'
	import Attributes from '@/components/attributes'
	import Locale from '@/components/locale'
	import whatsappIcon from '~/static/images/whatsapp-icon.svg?inline'

	export default {
		components: {
			Locale,
			Modifiers,
			Attributes,
			whatsappIcon
		},
		data () {
			return {
				showLocationSearch: false,
				images: [],
				referralCodeIcon: 'clipboard',
				showModifiers: false,
				showAttributes: false
			}
		},
		head () {
			const data = {
				meta: [],
				link: [],
				style: []
			}

			if (this.merchant) {
				if (this.merchant.settings.general.favicon) {
					data.link.push({
						rel: 'icon',
						type: 'image/x-icon',
						href: this.merchant.settings.general.favicon
					})
				}

				data.meta = [
					{ hid: 'apple-mobile-web-app-title', name: 'apple-mobile-web-app-title', content: `${this.merchant.business.name}` },
					{ hid: 'description', name: 'description', content: `${this.merchant.business.name} web app` },
					{ hid: 'og:title', name: 'og:title', content: `${this.merchant.business.name}` },
					{ hid: 'og:site_name', name: 'og:site_name', content: `${this.merchant.business.name}` },
					{ hid: 'og:description', name: 'og:description', content: `${this.merchant.business.name} web app` }
				]

				this.merchant.settings.general.store_meta?.forEach((meta) => {
					data.meta.push({
						hid: meta.name,
						name: meta.name,
						content: meta.content
					})
				})

				if (this.merchant.settings.app.appearance.theme_color.includes('#')) {
					data.style.push({
						cssText: `
						/* Custom color theme */
						:root 
							.bg-primary {
								color: #fff !important;
								background-color: ${this.merchant.settings.app.appearance.theme_color} !important;
							}
							.btn-outline-primary, .bi-three-dots-vertical {
								color: ${this.merchant.settings.app.appearance.theme_color} !important;
							}
							.btn-outline-primary:hover {
								color: #fff !important;
								background-color: ${this.merchant.settings.app.appearance.theme_color} !important;
							}
							.btn-primary, .active, .btn-outline-success {
								color: #fff !important;
								background-color: ${this.merchant.settings.app.appearance.theme_color} !important;
								border-color: ${this.merchant.settings.app.appearance.theme_color} !important;
							}
							.card-body {
								background-color:  #fff !important;
							}
							.card-body > a > p, .text-success, .breadcrumb-item > a {
								color: ${this.merchant.settings.app.appearance.theme_color} !important;
							}
							.navbar-dark, .navbar-nav,.navbar > a > p {
								color: rgba(255, 255, 255, 0.85) !important;
							}
							.card-link, .nav-link, .card-link:hover, .nav-link:hover, a.collapsed, .b-sidebar-body a {
								color: ${this.merchant.settings.app.appearance.theme_color};
							}
							.custom-control-input:checked~.custom-control-label::before, .badge, .btn-success {
								color: #fff;
								border-color: ${this.merchant.settings.app.appearance.theme_color} !important;
								background-color: ${this.merchant.settings.app.appearance.theme_color} !important;
							}
							span.bg-danger {
								color: ${this.merchant.settings.app.appearance.theme_color} !important;
								background-color: #fff !important;
								border-color: ${this.merchant.settings.app.appearance.theme_color} !important;
							}
							.breadcrumb-item.active {
								color: #6c757d !important;
								background-color: transparent !important;
							}
						}`,
						type: 'text/css'
					})
				}
			}

			return data
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.merchant
			},
			appliedCoupon () {
				return this.$store.state.appliedCoupon
			},
			location () {
				return this.$store.state.location
			},
			authComponent: {
				get () {
					return this.$store.state.authComponent
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'authComponent',
						value
					})
				}
			},
			user () {
				return this.$store.state.user
			},
			cart: {
				get () {
					return this.$store.state.cart
				},
				set (value) {
					this.$store.commit('setState', { key: 'cart', value })
				}
			},
			selectedStore () {
				return this.$store.state.selectedStore
			}
		},
		watch: {
			cart (value) {
				if (value.items.length === 0) {
					this.$store.commit('setState', {
						key: 'appliedCoupon',
						value: null
					})
				}

				this.$store.dispatch('updateSession', {
					key: 'cart',
					value
				})
			},
			selectedStore (value) {
				if (value) {
					this.$store.dispatch('updateSession', {
						key: 'selectedStore',
						value
					})
				}
			}
		},
		mounted () {
			this.$root.$on('changeDeliveryLocation', () => {
				this.showLocationSearch = true
			})

			this.$root.$on('showImages', (images) => {
				if (images.length) {
					this.images = images
					this.$refs.viewer.$viewer.show()
				}
			})

			this.$root.$on('show-attributes', () => { this.showAttributes = true })
			this.$root.$on('show-modifiers', () => { this.showModifiers = true })
			this.$root.$on('apply-modifiers', this.applyModifiers)
			this.$root.$on('add-item', this.addItem)
		},
		methods: {
			logout () {
				this.$bvModal.msgBoxConfirm(this.$t('logoutConfirmation'), {
					size: 'sm',
					centered: true,
					title: this.$options.filters.capitalize(this.$t('logout')),
					buttonSize: 'sm',
					okTitle: this.$t('yes'),
					cancelTitle: this.$t('no'),
					hideHeaderClose: false
				}).then((value) => {
					return value ? this.$store.dispatch('logout') : Promise.reject(new Error('USER-DECLINED'))
				}).then(this.reload)
			},
			focusLocationSearch (visible) {
				if (visible) {
					this.$refs.locationComponent.$el.querySelector('input').focus()
				}
			},
			onLocationChange () {
				if (this.$route.name === 'index') {
					this.$router.push('/items')
				} else if (this.$route.name === 'items') {
					this.reload()
				}
			},
			checkout () {
				this.$root.$emit('bv::hide::popover', 'cart-popover') // eslint-disable-line
				setTimeout(() => {
					this.$router.push('/checkout')
				}, 300)
			},
			copyToClipboard () {
				this.$refs.referralCode.$el.select()
				document.execCommand('copy')
				this.referralCodeIcon = 'clipboard-check'
			},
			shareReferralCode (platform) {
				const url = `${window.location.origin}?referral_code=${this.user.referral_code}`
				const text = encodeURIComponent(this.$t('referrerMsg', [this.merchant.business.name, url]))

				switch (platform) {
					case 'whatsapp':
						window.open(`whatsapp://send?text=${text}`)
						break
					case 'facebook':
						window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`)
						break
					case 'twitter':
						window.open(`http://twitter.com/share?text=${text}&url=${url}&hashtags=${
							this.merchant.business.name.replace(/ /g, '')
						},POSBytz,referrals`)
						break
				}
			},
			applyModifiers (item) {
				let modifierPrice = 0
				const variation = item.variations[item.variation_index]
				let quantity = variation.unit_measure_type === 'weight' && this.merchant.settings.general.quantity_increment_value ? +this.merchant.settings.general.quantity_increment_value : 1
				const cartItem = this.cart.items.find((i) => {
					const cartItemVariation = i.variations[i.variation_index]
					const iSelectedModifiers = cartItemVariation.selected_modifiers || []
					const itemSelectedModifiers = variation.selected_modifiers || []
					const newModifiers = iSelectedModifiers.filter(this.modifierComparer(itemSelectedModifiers))
						.concat(itemSelectedModifiers.filter(this.modifierComparer(iSelectedModifiers)))

					return cartItemVariation.id === variation.id && newModifiers.length === 0
				})

				if (cartItem) {
					quantity += cartItem.variations[cartItem.variation_index].quantity
				}

				modifierPrice = variation.selected_modifiers
					? variation.selected_modifiers.reduce((sum, m) => {
						sum += m.price

						return sum
					}, 0)
					: 0

				item.variations[item.variation_index].price = variation.original_price + modifierPrice
				this.addItem({ item, quantity })
			},
			checkWeight (quantity) {
				let result = true

				if (this.merchant.settings.order_weight_limit && (
					(this.cart.price.totalQuantity + quantity) > this.merchant.settings.order_weight_limit)
				) {
					result = false
				}

				return result
			},
			addItem ({ item, quantity }) {
				const variation = item.variations[item.variation_index]

				const addItem = () => {
					const cartItemIndex = this.cart.items.findIndex((i) => {
						const cartItemVariation = i.variations[i.variation_index]
						const iSelectedModifiers = cartItemVariation.selected_modifiers || []
						const itemSelectedModifiers = variation.selected_modifiers || []
						const newModifiers = iSelectedModifiers.filter(this.modifierComparer(itemSelectedModifiers))
							.concat(itemSelectedModifiers.filter(this.modifierComparer(iSelectedModifiers)))

						return cartItemVariation.id === variation.id && newModifiers.length === 0
					})

					if (cartItemIndex !== -1) {
						const cartItemVariation = this.cart.items[cartItemIndex].variations[this.cart.items[cartItemIndex].variation_index]

						if (quantity > 0 && this.checkWeight(quantity - cartItemVariation.quantity)) {
							cartItemVariation.quantity = quantity
						} else if (quantity <= 0) {
							cartItemVariation.quantity = 0
							this.cart.items.splice(cartItemIndex, 1)
						} else {
							this.showCartFullAlert()
						}
					} else if (this.checkWeight(quantity)) {
						variation.quantity = quantity
						this.cart.items.push(item)
					} else {
						this.showCartFullAlert()
					}

					this.cart.store = this.cart.items.length ? (this.cart.store || this.selectedStore) : null
					this.cart = Object.assign({}, this.cart)

					if (this.appliedCoupon && this.validateDiscount(this.appliedCoupon, this.cart)) {
						this.$store.dispatch('applyDiscount')
					}

					this.$store.dispatch('cartCalculation')
				}

				if (this.cart.store && this.selectedStore && this.cart.store.id !== this.selectedStore.id) {
					this.$bvModal.msgBoxConfirm(this.$t('itemFormOtherLocation'), {
						centered: true,
						title: this.$t('alert'),
						okTitle: this.$t('yes'),
						cancelTitle: this.$t('no'),
						hideHeaderClose: false
					}).then((value) => {
						if (value) {
							this.$store.dispatch('resetCart')
							addItem()
						}
					})
				} else {
					addItem()
				}
			}
		}
	}
</script>

<style lang="scss">
	#cart-popover {
		max-width: 400px;
		width: 100%;

		.popover-body {
			padding: 0;
			max-height: 400px;
			overflow-y: auto;
		}
	}
</style>
